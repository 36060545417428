<template>
  <div class="main-container">
    <section class="h-screen flex justify-center items-center second-container">
      <article class="flex flex-col items-center">

        <section class="p-5 players-list" style="min-width: 250px; border: 5px solid #E46B65;">
          <h1 class="text-2xl text-center font-bold mb-5" >Joueurs</h1>
          
          <article class="players-list-container text-center">
            <div v-for="(player, index) in players" :key="index">
              <p :style="index === 0 ? 'margin: 0' : ''">{{player.pseudo}} <i v-if="player.host" class="material-icons">star</i> </p>
            </div>
          </article>
        </section>

        <!-- <h1 class="text-xl font-bold text-center mt-5">Lien vers la partie</h1>
        <div class="flex justify-between w-full items-center mt-3 border-2 p-2 border-gray-100 rounded-md">
          <input value="http://localhost:8080/game" id="game-link" class="input w-full text-lg mr-2"/>
          <button class="bg-purple-500 px-2 text-white py-1 rounded-md" @click="copyToClipboard()">Copier</button>
        </div> -->

        <button v-if="$store.state.host" class="bg-green-500 p-3 mt-8 text-white text-lg rounded-md z-10" @click="startGame()">Lancer la partie</button>
      </article>

    </section>

    <img class="vague" src="../assets/wave.svg" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      players: [],
      messages: []
    };
  },
  computed: {
    socket () {
      return this.$store.state.socket
    }
  },
  methods: {
    ...mapActions(['setHost', 'setGameInProgress']),
    copyToClipboard() {
      const copyText = document.querySelector('#game-link');
      copyText.select();
      document.execCommand('copy');
    },
    startGame() {
      this.socket.emit('START_GAME')
    },
  },
  mounted () {
    if (!this.socket) { 
      this.$router.push('/login')
      return
    }

    this.socket.emit('GET_LIST_PLAYERS')

    this.socket.emit('CHECK_IF_GAME')
    this.socket.on('CHECK_GAME', (response) => {
      if (response) {
        this.setGameInProgress(true) 
        this.$router.push('/game') 
      }
    })

    this.socket.on('HOST', () => { this.setHost() })

    this.socket.on('LIST_PLAYERS', (players) => { this.players = players })
    this.socket.on('NEW_PLAYER', (players) => { this.players = players })
    this.socket.on('GO_TO_GAME', () => { this.$router.push('/game') })
  },
};
</script>

<style lang="scss" scoped>
.players-list {
  width: 500px;

  @media screen and (max-width: 1400px) {
    width: 300px;
  }

  p {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1400px) {
      margin-top: 0;
    }
  }

  i {
    color: yellow;
    margin-left: 5px;
  }
}

.main-container {
  position: relative;
  overflow: hidden;
  height: 100vh;

  .second-container {
    height: 70vh;

    @media screen and (max-width: 600px) {
      height: 100vh;
    }
  }

  .vague {
    position: absolute;
    bottom: 0;
    width: 100vw;
    max-width: unset!important;
    z-index: -1;

    @media screen and (max-width: 770px) and (max-height: 540px) {
      display: none;
    }
  }
}
</style>
